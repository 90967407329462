exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-ball-valve-js": () => import("./../../../src/pages/ball-valve.js" /* webpackChunkName: "component---src-pages-ball-valve-js" */),
  "component---src-pages-ball-valves-supplier-bahrain-js": () => import("./../../../src/pages/ball-valves/supplier-bahrain.js" /* webpackChunkName: "component---src-pages-ball-valves-supplier-bahrain-js" */),
  "component---src-pages-baumer-accessories-js": () => import("./../../../src/pages/baumer-accessories.js" /* webpackChunkName: "component---src-pages-baumer-accessories-js" */),
  "component---src-pages-baumer-accessories-pressure-js": () => import("./../../../src/pages/baumer-accessories-pressure.js" /* webpackChunkName: "component---src-pages-baumer-accessories-pressure-js" */),
  "component---src-pages-baumer-diaphrame-seals-js": () => import("./../../../src/pages/baumer-diaphrame-seals.js" /* webpackChunkName: "component---src-pages-baumer-diaphrame-seals-js" */),
  "component---src-pages-baumer-differential-pressure-gauges-js": () => import("./../../../src/pages/baumer-differential-pressure-gauges.js" /* webpackChunkName: "component---src-pages-baumer-differential-pressure-gauges-js" */),
  "component---src-pages-baumer-flow-meter-js": () => import("./../../../src/pages/baumer-flow-meter.js" /* webpackChunkName: "component---src-pages-baumer-flow-meter-js" */),
  "component---src-pages-baumer-level-sensor-js": () => import("./../../../src/pages/baumer-level-sensor.js" /* webpackChunkName: "component---src-pages-baumer-level-sensor-js" */),
  "component---src-pages-baumer-pressure-gauges-js": () => import("./../../../src/pages/baumer-pressure-gauges.js" /* webpackChunkName: "component---src-pages-baumer-pressure-gauges-js" */),
  "component---src-pages-baumer-pressure-switches-js": () => import("./../../../src/pages/baumer-pressure-switches.js" /* webpackChunkName: "component---src-pages-baumer-pressure-switches-js" */),
  "component---src-pages-baumer-pressure-transmitter-js": () => import("./../../../src/pages/baumer-pressure-transmitter.js" /* webpackChunkName: "component---src-pages-baumer-pressure-transmitter-js" */),
  "component---src-pages-baumer-temperature-gauges-js": () => import("./../../../src/pages/baumer-temperature-gauges.js" /* webpackChunkName: "component---src-pages-baumer-temperature-gauges-js" */),
  "component---src-pages-baumer-temperature-switches-js": () => import("./../../../src/pages/baumer-temperature-switches.js" /* webpackChunkName: "component---src-pages-baumer-temperature-switches-js" */),
  "component---src-pages-brands-js": () => import("./../../../src/pages/brands.js" /* webpackChunkName: "component---src-pages-brands-js" */),
  "component---src-pages-butterfly-valve-js": () => import("./../../../src/pages/butterfly-valve.js" /* webpackChunkName: "component---src-pages-butterfly-valve-js" */),
  "component---src-pages-butterfly-valves-supplier-bahrain-js": () => import("./../../../src/pages/butterfly-valves/supplier-bahrain.js" /* webpackChunkName: "component---src-pages-butterfly-valves-supplier-bahrain-js" */),
  "component---src-pages-check-valve-js": () => import("./../../../src/pages/check-valve.js" /* webpackChunkName: "component---src-pages-check-valve-js" */),
  "component---src-pages-check-valves-supplier-bahrain-js": () => import("./../../../src/pages/check-valves/supplier-bahrain.js" /* webpackChunkName: "component---src-pages-check-valves-supplier-bahrain-js" */),
  "component---src-pages-clients-js": () => import("./../../../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-gate-valve-js": () => import("./../../../src/pages/gate-valve.js" /* webpackChunkName: "component---src-pages-gate-valve-js" */),
  "component---src-pages-gate-valves-supplier-bahrain-js": () => import("./../../../src/pages/gate-valves/supplier-bahrain.js" /* webpackChunkName: "component---src-pages-gate-valves-supplier-bahrain-js" */),
  "component---src-pages-globe-valve-js": () => import("./../../../src/pages/globe-valve.js" /* webpackChunkName: "component---src-pages-globe-valve-js" */),
  "component---src-pages-globe-valves-supplier-bahrain-js": () => import("./../../../src/pages/globe-valves/supplier-bahrain.js" /* webpackChunkName: "component---src-pages-globe-valves-supplier-bahrain-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industrial-valves-js": () => import("./../../../src/pages/industrial-valves.js" /* webpackChunkName: "component---src-pages-industrial-valves-js" */),
  "component---src-pages-industrial-valves-supplier-bahrain-js": () => import("./../../../src/pages/industrial-valves/supplier-bahrain.js" /* webpackChunkName: "component---src-pages-industrial-valves-supplier-bahrain-js" */),
  "component---src-pages-pressure-gauge-baumer-js": () => import("./../../../src/pages/pressure-gauge-baumer.js" /* webpackChunkName: "component---src-pages-pressure-gauge-baumer-js" */),
  "component---src-pages-pressure-gauge-wika-js": () => import("./../../../src/pages/pressure-gauge-wika.js" /* webpackChunkName: "component---src-pages-pressure-gauge-wika-js" */),
  "component---src-pages-pressure-transmitter-baumer-js": () => import("./../../../src/pages/pressure-transmitter-baumer.js" /* webpackChunkName: "component---src-pages-pressure-transmitter-baumer-js" */),
  "component---src-pages-pressure-transmitter-wika-js": () => import("./../../../src/pages/pressure-transmitter-wika.js" /* webpackChunkName: "component---src-pages-pressure-transmitter-wika-js" */),
  "component---src-pages-products-details-js": () => import("./../../../src/pages/products-details.js" /* webpackChunkName: "component---src-pages-products-details-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-strainer-valve-js": () => import("./../../../src/pages/strainer-valve.js" /* webpackChunkName: "component---src-pages-strainer-valve-js" */),
  "component---src-pages-strainer-valves-supplier-bahrain-js": () => import("./../../../src/pages/strainer-valves/supplier-bahrain.js" /* webpackChunkName: "component---src-pages-strainer-valves-supplier-bahrain-js" */),
  "component---src-pages-temperature-gauges-baumer-js": () => import("./../../../src/pages/temperature-gauges-baumer.js" /* webpackChunkName: "component---src-pages-temperature-gauges-baumer-js" */),
  "component---src-pages-temperature-gauges-wika-js": () => import("./../../../src/pages/temperature-gauges-wika.js" /* webpackChunkName: "component---src-pages-temperature-gauges-wika-js" */),
  "component---src-pages-wika-flow-meter-js": () => import("./../../../src/pages/wika-flow-meter.js" /* webpackChunkName: "component---src-pages-wika-flow-meter-js" */),
  "component---src-pages-wika-level-sensor-js": () => import("./../../../src/pages/wika-level-sensor.js" /* webpackChunkName: "component---src-pages-wika-level-sensor-js" */),
  "component---src-pages-wika-pressure-gauges-js": () => import("./../../../src/pages/wika-pressure-gauges.js" /* webpackChunkName: "component---src-pages-wika-pressure-gauges-js" */),
  "component---src-pages-wika-pressure-switches-js": () => import("./../../../src/pages/wika-pressure-switches.js" /* webpackChunkName: "component---src-pages-wika-pressure-switches-js" */),
  "component---src-pages-wika-pressure-transmitter-js": () => import("./../../../src/pages/wika-pressure-transmitter.js" /* webpackChunkName: "component---src-pages-wika-pressure-transmitter-js" */),
  "component---src-pages-wika-temperature-gauges-js": () => import("./../../../src/pages/wika-temperature-gauges.js" /* webpackChunkName: "component---src-pages-wika-temperature-gauges-js" */)
}

